<template>
  <div>
    <div>
      <h2 class="th-title">Monitoring Tanggal {{ getHumanDate(monitoringDate) }}</h2>
      <a-date-picker v-model:value="monitoringDate" placeholder="Ganti tanggal" class="mb-2" />
      <a-button type="primary" class="ml-3" v-if="!data.length" @click="generateMonitoringTask()"
        :disabled="isAssetAvailalble">Generate
        Monitoring</a-button>
      <div v-if="isAssetAvailalble">
        <p style="color: #F28585; margin-bottom: 10px;">Asset belum ditambahkan. silahkan hubungi admin.</p>
      </div>
      <div>
        <p style="color: #B6BBC4; margin-bottom: 10px;">Data yang ditampilkan berdasarkan aktifitas eos per harinya.
          Ganti
          tanggal
          untuk melihat aktifitas eos per
          harinya.</p>
      </div>


      <!-- <a>
        <p class="text-muted mb-5" @click="showModalDate"> Ganti tanggal monitoring</p>
      </a> -->
      <!-- <a-modal v-model:visible="visibleDate" title="Basic Modal" @ok="handleDate">
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </a-modal> -->
    </div>
    <a-card>
      <div>
        <a-table :columns="columns" :data-source="data" bordered>
          <template #name="{ text, record }">
            <router-link :to="{ path: `/monitoring/detail/${record.id}` }" class="vb__utils__link">
              {{ text }}
            </router-link>
          </template>
          <template #action="{ record }">
            <div v-if="record.keterangan === 'Not Yet'">
              <WarningFilled :style="{ color: 'firebrick' }"> </WarningFilled> &nbsp;Monitoring belum dimulai
              <span v-if="record.key === idEdit">
              </span>
            </div>
            <div v-else-if="record.keterangan === 'Done'">
              <CheckCircleOutlined :style="{ color: 'green' }"> </CheckCircleOutlined> &nbsp; Monitoring sudah selesai
              <span v-if="record.key === idEdit">
              </span>
            </div>
            <div v-else-if="record.keterangan === 'On Progress'">
              <ClockCircleOutlined :style="{ color: '#FFF78A' }"> </ClockCircleOutlined> &nbsp; Monitoring belum
              selesai
              <span v-if="record.key === idEdit">
              </span>
            </div>
          </template>
        </a-table>
      </div>
    </a-card>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRaw, watch } from 'vue';
import { useStore } from 'vuex';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { Modal, notification } from 'ant-design-vue';
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined, WarningFilled, ClockCircleOutlined, CheckCircleOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';
import { useForm } from '@ant-design-vue/use';
import moment from 'moment'
import {
  generateTask, getTaskByDate, checkAssets,
} from '../../services/axios/modc/eos'
const columns = [
  {
    title: 'No',
    dataIndex: 'key',
    key: 'key',
  },
  {
    title: 'Nama Item',
    dataIndex: 'Asset.name',
    key: 'name',
    slots: { customRender: 'name' },
  },
  {
    title: 'Kategori',
    dataIndex: 'Asset.AssetSubCategory.AssetCategory.name',
    key: 'category',
    // slots: { customRender: 'description' },
  },
  {
    title: 'Sub-kategori',
    dataIndex: 'Asset.AssetSubCategory.name',
    key: 'subCategory',
  },
  {
    title: 'Customers',
    dataIndex: 'Asset.Customer.name',
    key: 'subCategory',
    sorter: (a, b) => a.Asset.Customer.name.localeCompare(b.Asset.Customer.name),
  },
  {
    title: 'Keterangan',
    key: 'ceklis',
    slots: { customRender: 'action' },
    align: 'center',
  },
];

export default {
  components: {
    WarningFilled, ClockCircleOutlined, CheckCircleOutlined,
  },

  setup() {
    const store = useStore()
    let data = ref([])
    let search = ref(null)
    let modalAddVisible = ref(false)
    let formAdd = reactive({
      name: null,
      description: null,
    })
    let formEdit = ref({
      name: null,
      description: null,
    })
    let idEdit = ref(null)
    const loadingAdd = ref(false)
    const rulesRef = reactive({
      name: [
        {
          required: true,
          message: 'Nama harus diisi!',
          trigger: 'change',
        },
      ],
    })


    const showModalAdd = () => {
      modalAddVisible.value = true
    }

    const handleOk = () => {
      modalAddVisible.value = false
    }

    const closeEdit = () => idEdit.value = null

    const { validate: validateEdit, validateInfos: validateInfosEdit } = useForm(formEdit, rulesRef);
    const confirmEdit = async (record) => {
      try {
        const payload = {
          id: record.key,
          data: {
            name: formEdit.value.name,
            description: formEdit.value.description,
          },
        }
        const tes = await validateEdit()
        // const tes2 = await validate()
        Modal.confirm({
          title: `Apa anda yakin ingin mengubah data pada kategori '${record.name}'?`,
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              const data = await store.dispatch('category/UPDATE_CATEGORY', payload)
              modalAddVisible.value = false
              fetchCategory()
              notification.success({
                message: `Berhasil Mengubah Data Pada Kategori '${record.name}'`,
              })
              idEdit.value = null
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() { },
        })
      } catch (err) {
        console.log(err)
      }
    }

    const { resetFields, validate, validateInfos } = useForm(formAdd, rulesRef);

    const confirmAdd = async () => {
      try {
        await validate()
        // console.log(console.log(toRaw(formAddRef)))
        Modal.confirm({
          title: 'Apa anda yakin ingin menambahkan kategori?',
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              const data = await store.dispatch('category/ADD_CATEGORY', { ...formAdd })
              modalAddVisible.value = false
              fetchCategory()
              notification.success({
                message: `Berhasil Menambahkan '${formAdd.name}' Dari Daftar Kategori`,
              })
              resetFields()
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() { },
        })
      } catch (err) { }
    }

    const categoryAction = async (record, action) => {
      const data = {
        id: record.key,
        data: {
          name: record.name,
          description: record.description,
        },
      }
      if (action === 'DELETE') {
        Modal.confirm({
          title: `Apa anda yakin ingin menghapus kategori '${record.name}' dari daftar?`,
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              const res = await store.dispatch('category/DELETE_CATEGORY', { ...data })
              fetchCategory()
              notification.success({
                message: `Berhasil Menghapus '${record.name}' Dari Daftar Kategori`,
              })
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() { },
        })
      } else if (action === 'UPDATE') {
        idEdit.value = record.key
        formEdit.value = {
          name: record.name,
          description: record.description || null,
        }
        // const data = await store.dispatch(action + '_CATEGORY', { id, data })
      }
    }

    const handleSearchCategory = () => {
      fetchCategory(search.value)
    }

    const visibleDate = ref(false);
    const showModalDate = () => {
      visibleDate.value = true;
    };
    const handleDate = e => {
      visible.value = false;
    };

    const monitoringDate = ref()
    watch(monitoringDate, (newValue, oldValue) => {
      // Fungsi ini akan berjalan setiap kali myVariable berubah

      // Panggil fungsi atau lakukan tindakan lain yang Anda inginkan di sini
      fetchCategory();
    });

    const generateMonitoringTask = async () => {
      const storedUserData = localStorage.getItem('userData')
      const userData = JSON.parse(storedUserData)
      const payload = {
        userId: userData.user.id,
        date: moment(monitoringDate.value).format('YYYY-MM-DD'),
      }
      const data = await generateTask(payload)
      fetchCategory()

    }
    const fetchCategory = async (search = '') => {
      console.log(moment(monitoringDate.value).format('DD MMM YYYY'))
      const storedUserData = localStorage.getItem('userData')
      const userData = JSON.parse(storedUserData)
      const payload = {
        userId: userData.user.id,
        date: moment(monitoringDate.value).format('YYYY-MM-DD'),
      }
      const { tasks } = await getTaskByDate({ params: payload })
      tasks.forEach((e, idx) => {
        e.key = idx + 1
      })
      data.value = tasks
    }
    const isAssetAvailalble = ref(true)
    const checkAsset = async () => {
      const storedUserData = localStorage.getItem('userData')
      const userData = JSON.parse(storedUserData)
      const { assetsCheck } = await checkAssets(userData.user.id)
      isAssetAvailalble.value = !assetsCheck

    }

    onMounted(() => {
      fetchCategory()
      checkAsset()
    })


    return {
      monitoringDate,
      visibleDate,
      showModalDate,
      handleDate,
      data,
      columns,
      search,
      modalAddVisible,
      showModalAdd,
      handleOk,
      formAdd,
      rulesRef,
      loadingAdd,
      confirmAdd,
      validate,
      validateInfos,
      validateEdit,
      validateInfosEdit,
      categoryAction,
      idEdit,
      formEdit,
      closeEdit,
      confirmEdit,
      handleSearchCategory,
      generateMonitoringTask,
      ClockCircleOutlined,
      isAssetAvailalble,
    }
  },

  methods: {
    getHumanDate: function (date) {
      return moment(date).format('DD MMM YYYY')
    },
  },
}
</script>
